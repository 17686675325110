import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCourses(ctx, queryParams) {
      console.log("courses Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get('/lms/admin/course/getallcourses', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCourse(ctx, { id }) {
      console.log("single course  Fetching");
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getcoursedetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateCourseInfo(ctx, { id }) {
      console.log("update course  is launching with id : "+id);
      return ;
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getcoursedetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

   
    addCourse(ctx, userData) {

      console.log("course Create triggered");
      console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/create', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    draftCourse(ctx, userData) {

      console.log("draftCourse Create triggered");
      console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/draft', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    publishCourse(ctx, userData) {

      console.log("publishCourse Create triggered");
      console.log(userData);

   
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/publish', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
